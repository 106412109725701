import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import GatsbyLink from '../../GatsbyLink/GatsbyLink';

function Wrapper({ children, onCTAClick, link, title, isCTA, ...props }) {
  const linkProps = {
    onClick: onCTAClick,
    to: link?.url,
    ariatext: title,
    activeClassName: 'active',
    target: '_self',
  };
  if (isCTA) {
    return (
      <GatsbyLink useItemTarget={false} {...linkProps} {...props}>
        {children}
      </GatsbyLink>
    );
  }
  return <div {...props}>{children}</div>;
}

Wrapper.propTypes = checkExtraProps({
  onCTAClick: PropTypes.func,
  title: PropTypes.string,
  link: PropTypes.shape({
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  isCTA: PropTypes.bool,
});

export default memo(Wrapper);
