import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';
import { graphql } from 'gatsby';
import { useSectionTimeSpent, useCSSMotion, sanitizer } from '../../util';
import Card from './Card/Card';

import './CardRow.scss';

function CardRow({ data: { cards, centerAligned, centerAlignedCards, description, title, withWaves } }) {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const contentMotionState = useCSSMotion(contentRef);

  useSectionTimeSpent(title, containerRef);

  return (
    <section ref={containerRef} className={classnames('CardRow')}>
      <div className={classnames('content', contentMotionState, { 'center-aligned': centerAligned })} ref={contentRef}>
        <h2 className="title">{title}</h2>
        <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
      </div>
      <div className={classnames('row')}>
        {cards?.map((card, index) => (
          <Card key={index} data={card} centerAligned={centerAlignedCards} withWaves={withWaves} />
        ))}
      </div>
    </section>
  );
}

CardRow.propTypes = checkExtraProps({
  data: PropTypes.shape({
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        icon: PropTypes.object,
        title: PropTypes.string.isRequired,
        link: PropTypes.shape({
          target: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
    ).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  centerAligned: PropTypes.bool,
  centerAlignedCards: PropTypes.bool,
  withWaves: PropTypes.bool,
});

export default memo(CardRow);

export const fragments = graphql`
  fragment CardRow on WpPage_Flexiblelayout_FlexibleChildren_CardRow {
    centerAligned
    centerAlignedCards
    description
    fieldGroupName
    title
    withWaves
    cards {
      description
      icon {
        ...Icon
      }
      link {
        target
        title
        url
      }
      title
    }
  }

  fragment CardRow_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_CardRow {
    centerAligned
    centerAlignedCards
    description
    fieldGroupName
    title
    withWaves
    cards {
      description
      icon {
        ...Icon
      }
      link {
        target
        title
        url
      }
      title
    }
  }
`;
