import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import GatsbyImage from 'gatsby-image';
import checkExtraProps from '@jam3/react-check-extra-props';
import { ReactComponent as WavesSvg } from '../../../assets/svgs/waves.svg';
import { useCTAClickTracking, useCSSMotion, sanitizer } from '../../../util';
import GatsbyLink from '../../GatsbyLink/GatsbyLink';
import Wrapper from './CardContentWrapper';

import './Card.scss';

function Card({ data: { description, icon, link, title }, centerAligned, hasWhiteText, withWaves }) {
  const containerRef = useRef(null);
  const containerMotionState = useCSSMotion(containerRef);
  const handleCtaClick = useCTAClickTracking(`Card_${title}`);

  const className = classnames('Card', {
    whiteText: hasWhiteText,
    centerAligned,
    withWaves,
    hasCTA: !!link,
  });

  return (
    <div className={className} ref={containerRef}>
      <Wrapper className="card-container" onCTAClick={handleCtaClick} link={link} title={title}>
        <div className={classnames('content', { container: centerAligned, ...containerMotionState })}>
          {icon && (
            <GatsbyImage
              className="icon"
              fluid={icon?.localFile.childImageSharp.fluid}
              alt={icon?.altText}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: centerAligned ? 'center' : 'left',
              }}
            />
          )}
          <h4 className="title">{title}</h4>
          <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
          {link && (
            <GatsbyLink
              to={link?.url}
              onClick={handleCtaClick}
              className="cta-container"
              ariatext={link?.title}
              activeClassName="active"
              target="_self"
            >
              {link?.title}
            </GatsbyLink>
          )}
        </div>
        {withWaves && (
          <div className="waves-container">
            <WavesSvg className="waves" />
          </div>
        )}
      </Wrapper>
    </div>
  );
}

Card.propTypes = checkExtraProps({
  data: PropTypes.shape({
    description: PropTypes.string,
    icon: PropTypes.object,
    title: PropTypes.string.isRequired,
    link: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  centerAligned: PropTypes.bool,
  withWaves: PropTypes.bool,
});

export default memo(Card);
